<template>
  <div class="px-4">
    <div class="calculator--result">
      <div class="title--text">{{ $t('mortgage.mortgageEstimate') }}</div>
      <div v-if="monthlyPaymentFixed && !isNaN(monthlyPaymentFixed)">
        <div class="subtitle--text">
          {{ `${$t('mortgage.fixedCostFor')} ${tenureInYearFixed} ${$t('general.year')}` }}
        </div>
        <div class="price--text">
          {{ $n(monthlyPaymentFixed, 'currency', 'id-ID') }}
        </div>
      </div>
      <div>
        <div class="subtitle--text">
          {{ `${$t('mortgage.floatingCost')}` }}
        </div>
        <div class="price--text">
          <div v-if="monthlyPayment && !isNaN(monthlyPayment)">
            {{ $n(monthlyPayment, 'currency', 'id-ID') }}
          </div>
          <div v-else>-</div>
        </div>
      </div>
      <a
        :href="mortgageLink"
        class="btn btn-primary mt-4"
        style="width: fit-content; margin: auto"
        v-if="monthlyPayment"
      >
        {{ $t('mortgage.enquireMortgage') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  components: {},
  computed: {
    ...mapState({
      downPayment: (state) => state.v2.mortgage.downPayment,
      monthlyPayment: (state) => state.v2.mortgage.monthlyPayment,
      monthlyPaymentFixed: (state) => state.v2.mortgage.monthlyPaymentFixed,
      tenureInYear: (state) => state.v2.mortgage.tenureInYear,
      tenureInYearFixed: (state) => state.v2.mortgage.tenureInYearFixed,
      propertyPrice: (state) => state.v2.mortgage.propertyPrice,
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
    ...mapGetters({}),
    mortgageLink() {
      let url = '/kpr?param=true';
      if (this.$route.query.project_uuid) {
        url += `&project_uuid=${this.$route.query.project_uuid}`;
      }
      if (this.$route.query.listing_uuid) {
        url += `&listing_uuid=${this.$route.query.listing_uuid}`;
      }
      if (this.$route.query.project_unit_type_uuid) {
        url += `&project_unit_type_uuid=${this.$route.query.project_unit_type_uuid}`;
      }
      if (this.tenureInYear) {
        url += `&tenure_in_year=${this.tenureInYear}`;
      }
      if (this.downPayment) {
        url += `&down_payment=${this.downPayment}`;
      }
      if (this.propertyPrice) {
        url += `&property_price=${this.propertyPrice}`;
      }
      return url;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.calculator--result {
  background-color: $color-beliruma-1-light;
  padding: 16px;
  border-radius: 16px;
  .title--text {
    font-size: $font-xxl;
    font-family: Poppins-Bold;
  }
  .subtitle--text {
    font-size: $font-lg;
    font-family: Poppins-SemiBold;
    margin-top: 16px;
  }
  .price--text {
    font-size: $font-xl;
    font-family: Poppins-Bold;
    color: $color-beliruma-1;
  }
}
</style>
